var DB = false;
const DB_NAME = 'app_db';
const DB_VERSION = 1; 

var dbClass = async function() {
	return new Promise((resolve, reject) => {

		if(DB) { return resolve(DB); }
		console.log('OPENING DB', DB);
		let request = window.indexedDB.open(DB_NAME, DB_VERSION);

		request.onerror = e => {
			console.log('Error opening db', e);
			reject('Error');
		};

		request.onsuccess = e => {
			DB = e.target.result;
			resolve(DB);
		};


		request.onupgradeneeded = (event) => {
			console.log("Upgrading DB");
			// Save the IDBDatabase interface
			var dab = event.target.result;
			// Create an objectStore for this database
			var queryStore = dab.createObjectStore("queries", { keyPath: "searchValue" });
			var ingredientStore = dab.createObjectStore("ingredients", { keyPath:"ingredientName"});
			ingredientStore.transaction.oncomplete = function() {
				console.log("Completed!");
				// Store values in the newly created objectStore.
				//var customerObjectStore = dab.transaction(["ingredients"], "readwrite").objectStore("ingredients");
			};
			queryStore.transaction.oncomplete = function(){
				console.log("Got query store");
			}
		};
	});
};
var db = dbClass();

module.exports = db;