import VueRouter from 'vue-router'
//import Task from '@/components/task/task'
//import Page from '@/pages/page'
//import TaskListPage from '@/pages/taskList'
/*import ShowIngredient from '@/pages/ShowIngredient'
import calculateIngredients from '@/pages/CalculateIngredientsNutes'
import AddRecipe from '@/pages/AddRecipe'
import NotFound from '@/pages/404'
import RoadMap from '@/pages/RoadMap'
import About from '@/pages/About'*/

const routes = [
  { name: 'show-recipes',          path: '/oppskrifter/',            component: ()=>import('@/pages/ShowRecipes')},
  { name: 'show-all-recipes',      path: '/alle-oppskrifter/',       component: ()=>import('@/pages/AllRecipes')},
  { name: 'show-recipe',           path: '/oppskrift/:recipe',       component: ()=>import('@/pages/ShowRecipe')},
  { name: 'show-ingredient-keywords',       path: '/ingrediens/:ingredient/:keywords',  component: ()=>import('@/pages/ShowIngredient')},
  { name: 'show-ingredient',       path: '/ingrediens/:ingredient',  component: ()=>import('@/pages/ShowIngredient')},
  { name: 'calculate-ingredients', path: '/beregn-ingredienser/',    component: ()=>import('@/pages/CalculateIngredientsNutes')},
  { name: 'om',                    path: '/om',                      component: ()=>import('@/pages/About')},
  { name: 'index',                 path: '/',                        component: ()=>import('@/components/MainSearch') },
  { name: 'roadmap',               path: '/roadmap',                 component: ()=>import('@/pages/RoadMap')},
  { name: 'add-recipe',            path: '/legg-til-oppskrift',      component: ()=>import('@/pages/AddRecipe')},
  { name: '404',                   path: '*',                        component: ()=>import('@/pages/404')},
  { name: '404-dyn',               path: '/404/:statement',          component: ()=>import('@/pages/404')},
  { name: 'edit-ingredient-desc',  path: '/rediger-ingrediens-beskrivelse', component: ()=>import('@/pages/EditIngredientDesc')},
  { path: '/show-recipes',           redirect: '/oppskrifter/'},
  { path: '/show-recipe/:recipe',    redirect: '/oppskrift/:recipe'},
  { path: '/ingredient/:ingredient', redirect: '/ingrediens/:ingredient'},
  { path: '/add-recipe',           redirect: '/legg-til-oppskrift'}
]

export default new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})