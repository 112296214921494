<template>
	<v-navigation-drawer 
		app
		right
		width="350"
		v-model="drawer"
		mobile-breakpoint="1040"
		>
		<v-list-item two-line>
			<v-list-item-content>
				<v-list-item-title class="headline">
				Min Matkurv
				</v-list-item-title>
				<v-list-item-subtitle>Oversikt over ingredienser du har lagt til</v-list-item-subtitle>
			</v-list-item-content>
		</v-list-item>
		<v-card v-if="$store.getters.searchText.length == 0" flat>
		<v-divider></v-divider>
		<v-card-text>Du har ikke lagt til noen ingredienser enda.</v-card-text>
	</v-card>
	<v-card
        v-for="(ingredient, index) in $store.getters.searchText"
        v-bind:key="ingredient.product+index"
        flat
    >
    <v-divider></v-divider>
    <v-card-title class="capitalFirst">
      <div style="margin: 0 10px 0 0">{{ingredient.product}}</div>
      <div v-if="ingredient.keywords">
        <v-chip v-for="(keyword) in ingredient.keywords" v-bind:key="ingredient.product+index+keyword">
          {{keyword}}
        </v-chip>
      </div>
		</v-card-title>

		<v-container>
            <v-row>
				<v-col cols="7">
					<v-text-field
            @input="valueMesChange($event, index)"
						dense
						label="Mengde"
						value="100"
            v-if="ingredient.portions.length == 0"
					>
					</v-text-field>
          <v-text-field
            @input="valueMesChange($event, index)"
            dense
            label="Mengde"
            value="1"
            v-else
          >
            
          </v-text-field>

				</v-col>
        <v-col cols="5">
          <v-select
          @input="itemSelectedChange($event, index)"
          :items="[...ingredient.portions, ...items]"
          :label="ingredient.measurement.type"
          solo
          dense
          ></v-select>
        </v-col>
			</v-row>
			Næringsinnhold per {{ingredient.measurement.value*ingredient.measurement.multiplier}}g
                <v-row class="my-4">
                  <v-col cols="4" 
                  v-for="(nutrient, nutes_index) in ingredient.nutrient_array" v-bind:key="nutrient.title+nutes_index+ingredient.measurement.multiplier">
                  <div
                    class="text-center mx-2"
                  >
                  <p class="my-1">
                  {{nutrient.title}}
                  </p>
                  <v-progress-circular
                      :rotate="-90"
                      :size="70"
                      :width="10"
                      :value="nutrient.value/nutrient.multiplier"
                      :color="nutrient.color"
                    >
                      {{ strip(nutrient.value/100*(ingredient.measurement.value*ingredient.measurement.multiplier))}}
                    </v-progress-circular>
                    </div>
                  </v-col>
                </v-row>
          <v-btn color="error" class="my-2" block @click="$store.commit('deleteIndexIngredients', index)">Fjern</v-btn>
          <v-btn block v-if="ingredient.keywords.length > 0" :to="{ name: 'show-ingredient-keywords', params: { ingredient: $encodeURL(ingredient.product), keywords: $joinKeywords(ingredient.keywords) } }">Les mer</v-btn>
          <v-btn block v-else :to="{ name: 'show-ingredient', params: { ingredient: $encodeURL(ingredient.product) } }">Les mer</v-btn>
          </v-container>
          </v-card>
          <template v-slot:append>
            <div class="pa-2">
              <v-btn 
              block
              :disabled="$store.getters.searchText.length == 0"
              color="primary"
              elevation="3"
              :loading="btnLoading"
              to="/beregn-ingredienser/"
              >
                Beregn Næringsinnhold
              </v-btn>
            </div>
            <div class="pa-2">
              <v-btn 
              block
              :disabled="$store.getters.searchText.length == 0"
              color="primary"
              elevation="3"
              :loading="btnLoading"
              @click="searchRecipes"
              >
                Søk På Oppskrifter
              </v-btn>
            </div>
          </template>
    </v-navigation-drawer>
    
</template>
<script>
  import axios from 'axios';

	export default {
		data:function(){
			return {
				slider:0,
        nutrient_select:{},
				items:[{text:"gram", value:[1, "gram"]},{text:"hg", value:[100, "hg"]},{text:"kg", value:[1000, "kg"]}],
        itemSelected:[],
        btnLoading:false,
        snackbar:false,
        snackbar_text:''
			}
		},
		computed: {
			drawer:{
				set(boolean){
					this.$store.commit('toggleSidebar', boolean);
				},
				get() {
					return this.$store.getters.sidebarToggle;
				}
			}
		},
    methods: {
      strip(number) {
        let num = Number(number).toFixed(2);
        if (num.match(/\./)) {
          num = num.replace(/\.?0+$/, '');
        }
        return Number(num);
      },
      itemSelectedChange(value, index){
        let measurement_type = value[1];
        let measurement_multiplier = value[0];
        this.$store.commit("changeMeasurement", {value:{
            type:measurement_type,
            multiplier:measurement_multiplier
        }, index:index});
      },
      valueMesChange(val, index){
        console.log(val, index);
          this.$store.commit("changeMeasurement", {value:{
            value:val
          }, index:index});
      },
      testClick(){
          console.log("Hello", this.$store.getters.searchText);
      },
      searchRecipes(){
        ///recipes/?ingredient=cheese&ingredient=onion
        console.log(this.$store.getters.searchText)
        //this.$router.push({ path: '/show-recipes' });
        let products = this.$store.getters.searchText.map(item => {
          return {product:item.product, keywords:item.keywords};
        });
        let headers = {
          'Content-Type': 'application/json'
        };
        console.log(products);
        axios.post('https://api.brukmat.no/recipe-from-ingredients', products, {headers:headers}).then((returnedData)=>{
          console.log("Returned data:",returnedData.data.data)
          var recipes = {};
          for(let items of returnedData.data.data){
            /*let recipesAll = items.reduce((acc, item)=>{
              if(!acc) acc={};
              console.log(acc);
              if(item.labels && item.labels.includes("Recipe")){
                if(!acc.title) item.properties.title;
              }
            });*/
            let title = null;
            let ingredientToPush = {};
            // First get the title
            for(let item of items){
              if(item.labels && item.labels.includes("Recipe")){
                if(!recipes[item.properties.title]) {
                  console.log("Setting title etc.");
                  recipes[item.properties.title] = {
                    ingredients:[],
                    img_url:item.properties.img_url,
                    mood:item.properties.mood,
                    difficulty:item.properties.difficulty
                  };
                }
                title = item.properties.title;
                break;
              }
            }
            console.log("Title",title);
            if(!recipes[title]) throw Error("Malformed data returned...");
            // Populate the array
            for(let item of items){
              console.log("Items:",item);
              if(item.labels && item.labels.includes("Ingredient_Container")){
                if(title === null) throw Error("Missing title...");
                ingredientToPush = { ...ingredientToPush, ...item.properties};
              }
              if(item.type && item.type === "USED_IN"){
                if(title === null) throw Error("Missing title...");
                ingredientToPush = { ...ingredientToPush, ...item.properties};
              }
            }
            recipes[title].ingredients.push(ingredientToPush);
          }
          console.log(recipes);
          let resultArray = [];
          for (let prop in recipes){
            let values = recipes[prop];
            let finalValues = {...values, ...{title:prop}}
            resultArray.push(finalValues);
          }
          console.log(resultArray);
          this.$store.commit('setRecipes', resultArray);
          this.$router.push({ path: '/oppskrifter/' });
        }).catch(()=>{
          //this.snackbar = true;
          //this.snackbar_text = "En nettverksfeil oppstod";
        });

      }
    }
	}
</script>
<style type="text/css">.capitalFirst {
  text-transform: capitalize;
}</style>